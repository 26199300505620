import BaseService from "../fmp/baseService";

export class FmpNewsService extends BaseService{

    async getPressReleases(page: number): Promise<any[]>{
        return BaseService.GetData(`${process.env.REACT_APP_FMP_URL}/api/v3/press-releases?page=${page}&apikey=${process.env.REACT_APP_FMP_KEY}`)
    }

    async getPressReleasesBySymbol(symbol: string, page: number): Promise<any[]>{
        return BaseService.GetData(`${process.env.REACT_APP_FMP_URL}/api/v3/press-releases/${symbol}?page=${page}&apikey=${process.env.REACT_APP_FMP_KEY}`)
    }
}