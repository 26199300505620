import { BrowserRouter, Route, Routes } from "react-router-dom"
import PageNotFound from "./app/components/shared/page-not-found"
import Verify from './app/auth/verify';
import SignIn from "./app/auth/sign-in";
import UserProtectedRoute from "./context/userProtectedRoute";
import ArticleQueue from "./app/components/article-queue/article-queue";
import ContentManager from "./app/components/content-manager/content";
import Dashboard from "./app/components/dashboard/dashboard";
import Journalists from "./app/components/journalists/journalists";
import Rankings from "./app/components/rankings/rankings";
import SubmitArticle from "./app/components/submit-article/submitArticle";
import SubmitJournalist from "./app/components/journalists/submit-journalist";
import DynamicFeedTable from "./app/components/shared/tables/dynamic-feed-table";
import SelectedTopicsTable from "./app/components/selected-topics/selected-topics-table";
import AddFeedSource from "./app/components/content-manager/feed-sources/AddFeedSource";
import PressReleaseTable from "./app/components/shared/tables/press-release-table";
import Signup from "./app/auth/signup";

const adminRoutes = () => {
  return <>
    <Route path='/' element={
      <UserProtectedRoute>
        <Dashboard />
      </UserProtectedRoute>} />
    <Route path='/rankings' element={
      <UserProtectedRoute>
        <Rankings />
      </UserProtectedRoute>} />
    <Route path='/submit-article' element={
      <UserProtectedRoute>
        <SubmitArticle />
      </UserProtectedRoute>} />
    <Route path='/queue/:articleId' element={
      <UserProtectedRoute>
        <ArticleQueue />
      </UserProtectedRoute>} />
    <Route path='/content-manager' element={
      <UserProtectedRoute>
        <ContentManager />
      </UserProtectedRoute>} />
    <Route path='/journalists' element={
      <UserProtectedRoute>
        <Journalists />
      </UserProtectedRoute>} />
    <Route path='/journalists/submit' element={
      <UserProtectedRoute>
        <SubmitJournalist />
      </UserProtectedRoute>} />
    <Route path='/top-leads' element={
      <UserProtectedRoute>
        <DynamicFeedTable />
      </UserProtectedRoute>
    }
    />
    <Route path='/press-releases' element={
      <UserProtectedRoute>
        <PressReleaseTable />
      </UserProtectedRoute>
    }
    />
    <Route path='/add-source' element={
      <UserProtectedRoute>
        <AddFeedSource />
      </UserProtectedRoute>
    } />
    <Route path='/selected-leads' element={
      <UserProtectedRoute>
        <SelectedTopicsTable />
      </UserProtectedRoute>
    }
    />
  </>
}

const journalistRoutes = () => {
  return <>
  </>
}

const baseRoutes = () => {
  return <>
    <Route path='/verify' element={<Verify />} />
    <Route path='*' element={<PageNotFound />} />
    <Route path='/sign-in' element={<SignIn />} />
    <Route path='/sign-up' element={<Signup />} />
  </>
}

const routeHandler = () => {
  return <>
    <BrowserRouter>
      <Routes>
        {adminRoutes()}
        {journalistRoutes()}
        {baseRoutes()}
      </Routes>
    </BrowserRouter>
  </>
}

export default routeHandler;