import { IBaseResult } from "../models/baseResults";
import { INewsletterModel } from "../models/content-models/newsletterModel";
import { NewsletterSubmission } from "../models/content-models/newsletterSubmissionModel";
import BaseService from "./baseService";

export class NewsletterService extends BaseService {

    async getNewsletter(): Promise<IBaseResult<INewsletterModel>>{
        // eslint-disable-next-line no-useless-concat
        return BaseService.GetData(`${process.env.REACT_APP_NEWSLETTER_API_URL}/api/newsletters/today`);
    }

    async submitNewsletter(newsletter: NewsletterSubmission): Promise<IBaseResult<boolean>> {
        return BaseService.PostData(`${process.env.REACT_APP_NEWSLETTER_API_URL}/api/newsletters`, newsletter)
    }

    async generateTodaysNewsletter(): Promise<IBaseResult<boolean>> {
        return BaseService.PostData(`${process.env.REACT_APP_NEWSLETTER_API_URL}/api/newsletters/today`, {})
    }
}