/* eslint-disable react-hooks/rules-of-hooks */
import { format } from "date-fns";
import parse from "html-react-parser";
import React, { useCallback, useEffect, useState } from "react";
import { IArticleModel } from "../../../models/content-models/articleModels";
import { ArticleService } from "../../../services/articleSerivce";
import { useNavigate, useParams } from "react-router-dom";
import { S3BucketService } from "../../../services/s3BucketService";
import AlertError from "../shared/alert-error";
import Logo from "../shared/logo";
import Loading from "../shared/loading";

const articleQueue = () => {

    const isDarkMode = () =>
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches;

    const articleService = new ArticleService();
    const s3Service = new S3BucketService();
    const navigate = useNavigate();
    const { articleId } = useParams();
    const [article, setArticle] = useState<IArticleModel>();
    const [message, setMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);

    const getArticleInQueue = useCallback(async () => {
        const query = `items=18&page=1&sort=desc`
        await articleService.getArticleInQueue(query, 'ArticleID', articleId!).then(async (response) => {
            setLoading(false);
            setSuccess(response.isSuccessful);
            if(response.isSuccessful){

                setArticle(response.result);
            }
            else{
                setMessage(response.errorMessage)
            }
        }).catch(err => {
            setMessage('Failed to get article in queue');
        })
    }, []);

    const confirmArticle = async (e: any) => {
        e.preventDefault();
        await articleService.confirmArticle(article?.articleID!).then((response) =>{
            navigate('/')
        }).catch((err: any) =>{
        })
    }

    const rejectArticle = async (e: any) =>{
        e.preventDefault();
        await articleService.rejectArticle(article?.articleID!).then((response) =>{
            navigate('/')
        }).catch((err: any) =>{
        })
    }

    useEffect(() => {
        getArticleInQueue();
    }, [])

    const controls = () => {
        return <>
            <div className="flex sticky top-0 z-50 bg-white">
                <button
                    type="button"
                    className="rounded-md m-2 bg-red-200 px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500"
                    onClick={e => rejectArticle(e)}
                >
                    Reject Article
                </button>
                <button
                    type="button"
                    className="rounded-md m-2 bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-green-600"
                    onClick={e => confirmArticle(e)}
                >
                    Approve Article
                </button>
            </div>
        </>
    }

    return <>
        {controls()}
        {success && !loading ? (
            <article className="relative isolate overflow-hidden bg-white dark:bg-gray-900 px-6 py-12 sm:py-32 lg:overflow-visible lg:px-0">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10 ">
                    <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 ">
                        <div className="lg:pr-4">
                            <div className="lg:max-w-lg">
                                <p className="text-base font-semibold leading-7 text-green-600">{`${format(
                                    article?.submissionDate || new Date(),
                                    "EEEE, MMMM dd, yyyy"
                                )}`}</p>
                                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-slate-200">
                                    {article?.articleTitle}
                                </h1>
                                <p className="mt-6 text-xl leading-8 text-gray-700 dark:text-slate-300">
                                    {article?.articleLead}
                                </p>
                                <h5 className="mt-6 text-xl leading-8 text-gray-700 dark:text-slate-200">
                                    <a className="dark:text-slate-200"
                                        href={`/journalist/${article?.journalist.journalistID}`}
                                    >
                                        By {article?.journalist.firstName}{" "}
                                        {article?.journalist.middle}{" "}
                                        {article?.journalist.lastName}
                                    </a>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className=" p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
                        <img
                            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                            src={article?.image}
                            alt=""
                        />
                    </div>
                    <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
                        <div className="lg:pr-4">
                            <p className="prose child dark:text-slate-200">{parse(`${article?.content}`)}</p>
                        </div>
                    </div>
                    <div className="relative mt-8 ms-8 flex items-center gap-x-4">
                        <div className="text-md leading-6 p-4">
                            <img
                                src={article?.journalist.profileImageUrl}
                                alt=""
                                className="avatar-img"
                            />
                            <p className="font-semibold text-xl text-gray-900 dark:text-slate-200">
                                <a>
                                    <span className="absolute inset-0" />
                                    {article?.journalist.firstName}{" "}
                                    {article?.journalist.middle}{" "}
                                    {article?.journalist.lastName}
                                </a>
                            </p>
                            <p className="text-gray-600 dark:text-slate-200">
                                {article?.journalist.expertiseCategories}
                            </p>
                            <p className="mt-6 text-xl leading-8 text-gray-700 dark:text-slate-200">
                                {article?.journalist.byline}
                            </p>
                        </div>
                    </div>
                </div>
            </article>
        ) : message !== '' && !success ? <><AlertError message={message}/> </> : loading ? <><Loading /></> : <></>}
    </>
}

export default articleQueue;