/* eslint-disable @typescript-eslint/no-unused-vars */
import BaseService from "./baseService";
import { PutObjectCommand, S3Client, GetObjectCommand, PutObjectCommandInput } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

export class S3BucketService extends BaseService{

    private S3_BUCKET = "mkepost-image-bucket";
    private REGION = "us-east-2";
    private s3;
    private storage = {} as any;
    private upload = {} as any;

    constructor() {
        super();
        this.s3 = new S3Client({
            region: this.REGION,
            credentials: {
                accessKeyId:'AKIA6IY36BTKDU5FFW7U',
                secretAccessKey: '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'
            }
        });
    }

    async putNewImage(fileLocation: string, imageDetails: any): Promise<any>{

        const key = `${fileLocation}/${imageDetails.name}`

        const params = {
            Bucket: this.S3_BUCKET,
            Key: key,
            Body: imageDetails,
            ContentType: imageDetails.type,
            ACL: 'public-read'
        } as PutObjectCommandInput;

        const command = new PutObjectCommand(params)
        localStorage.setItem('presigned-key', key);
        await this.s3.send(command);
    }

    async getPresignedUrl(fileLocation: string): Promise<string>{

        const key = `${fileLocation}`
        const getObjectParams = {
            Bucket: this.S3_BUCKET,
            Key: key,
        }

        const command = new GetObjectCommand(getObjectParams);
        const url = await getSignedUrl(this.s3, command, { expiresIn: 3600 });
        return url;
    }
}