/* eslint-disable react-hooks/rules-of-hooks */
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react/jsx-runtime";
import logo from "../components/shared/logo";
import { Navigate } from "react-router-dom";
import { CognitoService } from "../../services/cognitoService";
import { useEffect, useState } from "react";
import { IJournalistModel } from "../../models/content-models/journalistModels";
import { JournalistService } from "../../services/journalistService";


const userNav = () => {
    
    const cognito = new CognitoService();
    const journalistService = new JournalistService()
    const [journalist, setJournalist] = useState<IJournalistModel>()
    // get journalist

    // const getJournalist = () =>{

    //     await cognito.getUser().then()
    //     await journalistService.getJournalist()
    // }

    const signOut = async () => {
        await cognito.signOut().then(async () => {
          window.localStorage.clear();
          window.location.reload();
    
          return <Navigate to="/" replace={true} />;
        });
      };

    //   useEffect(() =>{
    //     getJournalist()
    //   },[])

    return <>
        <div className="flex">
            <div className="flex flex-shrink-0 items-center">
                {logo()}
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                <a
                    href="/"
                    className="inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900"
                >
                    Dashboard
                </a>
                <a
                    href="/submit-article"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                    Submit Article
                </a>
                <a
                    href="/rankings"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                    Article Rankings
                </a>
                {/* <a
                    href="/breaking-events"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                    Breaking Events
                </a> */}
                <a
                    href="/content-manager"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                    Content Manager
                </a>
                <a
                    href="/journalists"
                    className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                >
                    Journalists
                </a>
            </div>
        </div>
        <div className="hidden sm:ml-6 sm:flex sm:items-center">

            {/* Profile dropdown */}
            <Menu as="div" className="relative ml-3">
                <div>
                    <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <img
                            className="h-8 w-8 rounded-full"
                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                            alt=""
                        />
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                            {({ active }) => (
                               <a
                                 onClick={signOut}
                                 className={classNames(
                                   active ? "bg-gray-100" : "",
                                   "block px-4 py-2 text-sm text-gray-700"
                                 )}
                               >
                                 Sign out
                               </a>
                             )}
                        </Menu.Item>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    </>
}

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default userNav;