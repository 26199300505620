'use client'
import { useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { CognitoService } from "../../services/cognitoService";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { BeatLoader } from "react-spinners";
import useSession from "../../hooks/useSession";
import { AuthService } from "../../services/authService";
import AlertMessage from "../components/alerts/alert-error";
import { useNavigate } from "react-router-dom";
import { SignUpInput, SignUpOutput } from "aws-amplify/auth";

const Signup = () => {
    const USER_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const PWD_REGEX = /^(?=.*[a-z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}$/;

    const auth = new AuthService();
    const cognito = new CognitoService();
    const { doesUserExist } = useSession();

    const [email, setEmail] = useState("");
    const [validName, setValidName] = useState(false);
    const [userFocus, setUserFocus] = useState(false);
    const navigate = useNavigate();

    const [pwd, setPwd] = useState("");
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);

    const [matchPwd, setMatchPwd] = useState("");
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);
    const cancelButtonRef = useRef(null);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [messageSeverity, setMessageSeverity] = useState(2);

    const [cognitoId, setCognitoId] = useState('')
    const [cognitoSuccess, setCognitoSuccess] = useState(false)

    const validatePwdMatch = () => {
        //const v1 = USER_REGEX.test(email);
        const v2 = PWD_REGEX.test(pwd);
        if (v2 && matchPwd === pwd) {
            return true;
        }
        else {
            return false;
        }
    }

    const cognitoSignUp = async (signupInput: SignUpInput): Promise<void> => {
        console.log('cognito sign up')
        await cognito.signUp(signupInput).then(async (cognitoResponse: SignUpOutput) => {
            setCognitoId(cognitoResponse.userId!)
            setCognitoSuccess(true)
            
            // should already have journalist record created

            navigate('/verify', { replace: true })
        }).catch(err => {
            setMessage('Failed to subscribe')
            setLoading(false)
            throw err
        })
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setSubmitted(true);
        console.log('handle submit')

        const exists = await doesUserExist(email)

        console.log('exists: ', exists)

        if (validatePwdMatch() && !exists) {
            // go to /checkout
            const user = {
                username: email,
                password: pwd
            } as SignUpInput;

            localStorage.setItem('temp-email', email)

            // check if user exists
            console.log('valid match')
            cognitoSignUp(user)
        }
        else {
            setLoading(false)
            console.log('invalid match')
            if(exists){
                setMessage('Invalid email');
            }
            else{
                setMessage('Invalid Password');
            }

        }
    }

    return <>
        <div className='pt-12'>
            {/* Checkout form */}
            <section
                aria-labelledby="payment-heading"
                className=" overflow-y-auto px-4 pb-16 pt-12 sm:px-6 sm:pt-16 lg:px-8 lg:pb-24 lg:pt-0"
            >
                <div className="mx-auto max-w-lg ">
                    {/* <div className="flex justify-center items-center w-full sm:max-w-xs">
                        <img
                            width="300vw"
                            height="100vw"
                            src="/trendline-news-light.png"
                        />
                    </div> */}

                    <form className="mt-6 bg-transparent p-5 shadow-md border border-gray-300">
                        <p className="text-center text-gray-800 text-2xl">Sign Up</p>

                        <div className="grid grid-cols-12 gap-x-4 gap-y-6">
                            <div className="col-span-full">
                                <label htmlFor="name-on-card" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                        required
                                        autoComplete="email"
                                        className="block w-full p-1 rounded-sm border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="card-number" className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        onChange={(e) => setPwd(e.target.value)}
                                        value={pwd}
                                        autoComplete="current-password"
                                        required
                                        className="block rounded-sm w-full p-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="card-number" className="block text-sm font-medium text-gray-700">
                                    Confirm Password
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        type="password"
                                        onChange={(e) => setMatchPwd(e.target.value)}
                                        value={matchPwd}
                                        autoComplete="current-password"
                                        required
                                        className="block rounded-sm w-full p-1 border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                        </div>
                        {!loading && submitted ? <>{
                            <AlertMessage message={message} severity={messageSeverity} />
                        }</> : <></>}
                        <button
                            type="submit"
                            className="mt-6 w-full rounded-sm border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                            disabled={loading}
                            onClick={(e) => handleSubmit(e)}
                        >
                            {loading ? (
                                <>
                                    <BeatLoader
                                        color="#FFFFFF"
                                        loading={loading}
                                        size={18}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </>
                            ) : (
                                <>Continue</>
                            )}
                        </button>

                        <p className="mt-6 flex justify-center text-sm font-medium text-gray-500">
                            Already Subscribed?&ensp;<a className="text-green-700 hover:text-green-600" href={'/sign-in'}>Sign in</a>
                        </p>
                    </form>
                </div>
            </section>
        </div>
    </>
}

export default Signup;