import { IBaseResult } from "../models/baseResults";
import { IJournalistModel } from "../models/content-models/journalistModels";
import BaseService from "./baseService";

export class JournalistService extends BaseService{
    async getAllJournalists(query: string): Promise<IBaseResult<IJournalistModel>>{
        return BaseService.GetData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/journalists?${query}`)
    }

    async getJournalist(journalistId: string): Promise<IBaseResult<IJournalistModel>>{
        return BaseService.GetData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/journalists/${journalistId}`)
    }

    async postJournalist(Journalist: IJournalistModel): Promise<IBaseResult<boolean>>{
        return BaseService.PostData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/journalists`, Journalist)
    }

    async putJournalist(Journalist: IJournalistModel, journalistId: string): Promise<IBaseResult<boolean>>{
        return BaseService.PutData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/journalists/${journalistId}`, Journalist)
    }

    async generateToken(session: any): Promise<IBaseResult<any>> {
        return BaseService.PostData(`${process.env.REACT_APP_JOURNALISTS_API_URL}/api/journalists/token`, session);
    }
}