import { IRequest } from "../../models/request-models/IRequest";

export default abstract class BaseService{

    static async GetData<T>(endpointUrl: string, httpReqObj?: Omit<IRequest, 'method' | 'body'>): Promise<any>{
        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        };

        return await fetch(endpointUrl, options)
            .then((response) => response.json())
            .then((data) =>{
                return data;
            })
            .catch((error) =>{
                console.error(error);
            })
    }

    static async PostData<T>(endpointUrl: string, body: any, httpReqObj?: Omit<IRequest, 'method' | 'body'>): Promise<any>{
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        };

        return await fetch(endpointUrl, options)
            .then((response) => {
                const result = response.json();
                return result;
            })
            .then((data) =>{
                return data;
            })
            .catch((error) =>{
                console.error(error);
            })
    }

    static async PutData<T>(endpointUrl: string, body: any, httpReqObj?: Omit<IRequest, 'method' | 'body'>): Promise<any>{
        const options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        };

        return await fetch(endpointUrl, options)
            .then((response) => response.json())
            .then((data) =>{
                return data;
            })
            .catch((error) =>{
                console.error(error);
            })
    }

    static async DeleteData(endpointUrl: string, httpReqObj?: Omit<IRequest, 'method' | 'body'>): Promise<any> {
        const options = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            next: { revalidate: 60}
        };

        return await fetch(endpointUrl, options)
            .then((response) => response.json())
            .then((data) => {
                return data;
            })
            .catch((error) => {
                console.error(error);
            })
    }
}

