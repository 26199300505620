/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";
import IFile from "../../../models/request-models/IFile";
import { PhotoIcon } from "@heroicons/react/24/outline";
import AlertError from "../shared/alert-error";
import { S3BucketService } from "../../../services/s3BucketService";
import React from "react";

const imageUpload = ({bucketName}: any) => {

    const [currentImage, setCurrentImage] = useState<any>({});
    const [previewImage, setPreviewImage] = useState<string>('');
    const [progress, setProgress] = useState<number>(0);
    const [presignedUrl, setPresignedUrl] = useState('');
    const [message, setMessage] = useState<string>("");
    const [error, setError] = useState<string>('');

    const [imageInfos, setImageInfos] = useState<Array<IFile>>([]);

    const selectImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFiles = event.target.files as FileList;
        setCurrentImage(selectedFiles?.[0]);
        const urlFile = URL.createObjectURL(selectedFiles?.[0]);
        setPreviewImage(urlFile);
        setProgress(0);
    };

    const generatePresignedUrl = async () => {



    }

    const upload = async (e: any) => {
        e.preventDefault();
        if (!currentImage) return;

        try {
            const s3BucketService = new S3BucketService();
            await s3BucketService.putNewImage(bucketName, currentImage).then((response) =>{
                setMessage("Image Uploaded");
            }).catch(err =>{
                console.error("Error uploading file:", err);
                setError('Failed to upload file to S3 Bucket');
            });
        }
        catch (err) {
            setError('Failed to upload file to S3 Bucket');
        }
    }

    const remove = (e: any) => {
        e.preventDefault();
        setCurrentImage(null);
        setPreviewImage('');
        localStorage.removeItem('presigned-url');
    }

    return <>
        <div className="col-span-full">
            <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                Cover photo
            </label>
            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                <div className="text-center">
                    {previewImage ?
                        <div className="h-96 w-96">
                            <img className="preview mt-2" src={previewImage} alt="" />
                        </div>
                        : <>
                            <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                            <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                <label className="btn btn-default p-0">
                                    <input type="file" accept="image/*" onChange={selectImage} />
                                </label>
                                <p className="pl-1">or drag and drop</p>
                            </div>
                            <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                        </>
                    }
                </div>
            </div>
        </div>
        <div>
            {error !== '' ? <>{<AlertError errorMessage={error} />}</> : <></>}

            <div className="flex justify-end">
                <button
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm m-2 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    disabled={!currentImage}
                    onClick={(e) => upload(e)}
                >
                    Upload Image
                </button>
                <button
                    className="rounded-md bg-transparent border border-red-500 px-3 py-2 text-sm m-2 font-semibold text-red-500 shadow-sm hover:bg-red-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                    disabled={!currentImage}
                    onClick={(e) => remove(e)}
                >
                    Remove Image
                </button>
            </div>

            {message && (
                <div className="alert alert-secondary mt-3" role="alert">
                    {message}
                </div>
            )}

            <p>
            presigned url: {presignedUrl !== '' ? <>
                <a href={`${presignedUrl}`}>Image Url</a>
            </> : <></>}
                
            </p>

            {imageInfos.length > 0 && (
                <div className="card mt-3">
                    <div className="card-header">List of Images</div>
                    <ul className="list-group list-group-flush">
                        {imageInfos.map((img, index) => (
                            <li className="list-group-item" key={index}>
                                <p>
                                    <a href={img.url}>{img.name}</a>
                                </p>
                                <img src={img.url} alt={img.name} height="80px" />
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div></>
}

export default imageUpload;
