/* eslint-disable no-undef */
/* eslint-disable react-hooks/rules-of-hooks */
import { Editor } from "@tinymce/tinymce-react";
import { useRef, useState } from "react";

const tinyMceEditor = ({ initialValue }: any) => {
  const editorRef = useRef({} as any);
  const [dirty, setDirty] = useState(false);
  //   useEffect(() => setDirty(false), [initialValue]);

  const extractPlainTExtFromHTML = (html: string) =>{
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, "text/html")
    return doc.body.textContent || ""
  }

  const save = () => {
    if (editorRef.current) {
      const content = editorRef.current!.getContent();
      const plainText = extractPlainTExtFromHTML(content)
      console.log(plainText)
      setDirty(false);
      editorRef.current!.setDirty(false);
      // an application would save the editor content to the server here
      localStorage.setItem('saved-new-article', content);
      localStorage.setItem('plaintext', plainText);
    }
  };

  return (
    <>
      <Editor
        apiKey="j2snsiv88yr49ik2oqqdrbou5ec67spbtyuggj9jmn3a7yay"
        onInit={(evt, editor) => (editorRef.current = editor)}
        onDirty={() => setDirty(true)}
        init={{
          plugins:
            "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker  ",
          toolbar:
            "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
          tinycomments_mode: "embedded",
          tinycomments_author: "Author name",
          mergetags_list: [
            { value: "First.Name", title: "First Name" },
            { value: "Email", title: "Email" },
          ],
          ai_request: (request: any, respondWith: any) =>
            respondWith.string(() =>
              Promise.reject("See docs to implement AI Assistant")
            ),
        }}
      />
      <button
        onClick={save}
        disabled={!dirty}
        className="rounded-md bg-indigo-600 px-3 mt-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Save Content
      </button>
      {dirty && <p>You have unsaved content!</p>}
    </>
  );
};

export default tinyMceEditor;
