import { useCallback, useEffect, useState } from "react"
import { FmpNewsService } from "../../../../services/fmp/fmpNewsService"
import { IPressRelease } from "../../../../models/fmp-models/pressRelease";
import LoadingIndicator from "../loading";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

const PressReleaseTable = () =>{

    const fmpNewsService = new FmpNewsService();
    const [pressReleases, setPressReleases] = useState<IPressRelease[]>([] as IPressRelease[])
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState(250)
    const [page, setPage] = useState(0)


    const getPressReleases = useCallback(async () => {
        const queryString = `&items=${items}&page=${page}`
        await fmpNewsService.getPressReleases(page).then((response: IPressRelease[]) =>{
            console.log('press releases', response)
            setPressReleases(response)
        }).catch((err) =>{

        }).finally(() => setLoading(false))
    },[])

    useEffect(() =>{
        getPressReleases()
    },[])

    return <>
    <div className="sm:px-2 lg:px-2">
            <div className="sticky top-0 grid grid-rows-1 grid-cols-5 gap-2">

            </div>
            <div className="mt-1 flow-root">
                <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle">
                        {!loading ? <>
                            <table className="min-w-full border-separate border-spacing-0">
                                <thead>
                                    <tr>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                        >
                                            Symbol
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                                        >
                                            Title
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                                        >
                                            Published
                                        </th>
                                        <th
                                            scope="col"
                                            className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                                        >
                                            Description
                                        </th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {pressReleases.map((item, itemIdx) => (
                                        <tr key={item.title} className="hover:bg-gray-100">
                                            <td
                                                className={classNames(
                                                    itemIdx !== itemIdx - 1 ? 'border-b border-gray-200' : '',
                                                    'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8',
                                                )}
                                            >
                                                {item.symbol}
                                            </td>
                                            <td
                                                className={classNames(
                                                    itemIdx !== itemIdx - 1 ? 'border-b border-gray-200' : '',
                                                    'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell',
                                                )}
                                            >
                                                {item.title}
                                            </td>
                                            
                                            <td
                                                className={classNames(
                                                    itemIdx !== itemIdx - 1 ? 'border-b border-gray-200' : '',
                                                    'whitespace-nowrap px-3 py-4 text-sm text-gray-500',
                                                )}
                                            >
                                                {item.date}
                                            </td>
                                            <td
                                                className={classNames(
                                                    itemIdx !== itemIdx - 1 ? 'border-b border-gray-200' : '',
                                                    'hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell',
                                                )}
                                            >
                                                {item.text}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </> : <> <LoadingIndicator /></>}
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default PressReleaseTable