import { jwtDecode } from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { AuthUser, ConfirmSignUpInput, SignInInput } from "aws-amplify/auth";
import { CognitoService } from "../services/cognitoService";
import { AuthService } from "../services/authService";
import { JournalistService } from "../services/journalistService";
import { IBaseResult } from "../models/baseResults";

const useSession = () => {

  const journalistService = new JournalistService()
  const auth = new AuthService();
  const cognito = new CognitoService();

  const [userToken, setUserToken] = useState({} as any);
  const [userRecord, setUserRecord] = useState<any>();

  const [cookie, setCookie, removeCookie] = useCookies(['session']);
  const [userCookie, setUserCookie] = useCookies(['_new_user'])
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [cognitoUser, setCognitoUser] = useState<AuthUser>();

  const getToken = async (): Promise<any> => {
    const session = localStorage.getItem('session')
    console.log('session', session)
    if (session!== undefined) {
      const decodedString = jwtDecode<any>(session || '{}');
      setUserToken(decodedString);
      return decodedString
    }
    else{
      const newToken = await generateToken()
      console.log('new token', newToken)
      const decodedString = jwtDecode<any>(newToken);
      setUserToken(decodedString);
      return decodedString
    }
  };

  // session calls

  const generateToken = async (): Promise<string> => {
   return await auth.getUserMetaData().then(async (result) => {

      return await getCognitoUser().then(async (response: AuthUser) => {
        console.log(response)
        let sessionDetails = {
          SessionId: result,
          Role: '',
          JournalistID: '',
          CognitoUsername: response.userId
        };

        return await journalistService.generateToken(sessionDetails).then((results: IBaseResult<string>) => {
          console.log('generate token: ', results)
          if (results.isSuccessful) {
            setCookie('session', results.result);
            return results.result
          }
          else {
            throw new Error(results.errorMessage)
          }
        }).catch((error) => {
          throw error
        })
      }).catch((error) => {
        throw error
      })
    })
  }

  const fetchUserRecord = async (key: string, value: any) => {
    return await journalistService.getJournalist(value).then((response: IBaseResult<any>) => {
      return response
    }).catch(err => {

    }).finally(() => {

    })
  }

  const createUserRecord = async (user: any): Promise<IBaseResult<any>> => {
    console.log('create user record')
    return await journalistService.postJournalist(user).then((response: IBaseResult<any>) => {
      return response
    }).catch((err) => {
      console.log(err)
      return err
    })
  }

  const updateUserRecord = (key: string, value: any) => {

  }

  const doesUserExist = async (email: string): Promise<any> => {
    console.log('does user exist')
    return await journalistService.getJournalist(email).then((response: IBaseResult<any>) => {
      return response.result
    }).catch((err) => {
      return false
    })
  }

  // cognito calls

  const getCognitoUser = async (): Promise<AuthUser> => {
    return await cognito.getUser().then((cognitoUser: AuthUser) => {
      console.log('cognito user', cognitoUser)
      return cognitoUser
    }).catch(err => {
      throw err
    })
  }

  const isAuthorized = async (): Promise<boolean> =>{
    return await cognito.promiseAuthenticated().then((response: boolean) => {
      setAuthorized(response);
      return response
    })
  }

  const authorize = useCallback(async () => {
    await cognito.promiseAuthenticated().then((response: boolean) => {
      setAuthorized(response);
    })
  }, []);

  const confirmSignUp = async (confirmSignup: ConfirmSignUpInput, signInInput: SignInInput): Promise<boolean> => {
    return await cognito.confirmSignUp(confirmSignup).then(async () => {
      return await signIn(signInInput).then(() => {
        return true
      }).catch((err: any) => {
        console.log(err)
        return false
      })
    }).catch((err: any) => {
      console.log(err)
      return false
    })
  }

  const signIn = async (signIn: SignInInput): Promise<boolean> => {
    return await cognito.signIn(signIn).then(async () => {
      //await generateToken()
      window.location.href = '/'
      return true
    }).catch((error: any) => {
      console.log(error)
      setLoading(false);
      if (error.type === "UserNotConfirmedException") {
        window.location.href = '/verify'
        return false
      } else if(error.type === "NotAuthorizedException") {
        throw "Login Failed"
      }
      else{
        throw "failed to sign in"
      }
    })
  }

  const signOut = async (e: any) => {
    await cognito.signOut().then(() => {
      localStorage.clear()
      removeCookie('session')
      window.location.href = '/'
    });
  }

  const resend = async (email: string): Promise<boolean> => {
    return await cognito.resendCode(email).then((response) => {
      return true;
    }).catch(() => {
      return false
    })
  }

  useEffect(() => {
    console.log('authenticated')
  }, [userRecord])

  useEffect(() => {
    authorize();
  }, [])


  return {
    userRecord,
    cognitoUser,
    userToken,
    authorized,
    getToken,
    fetchUserRecord,
    createUserRecord,
    updateUserRecord,
    //generateToken,
    doesUserExist,
    isAuthorized,
    signOut,
    signIn,
    resend,
    getCognitoUser,
    confirmSignUp
  };
}

export default useSession;