/* eslint-disable react-hooks/rules-of-hooks */
import { Fragment, useCallback, useEffect, useState } from "react"
import { IArticleModel } from "../../../models/content-models/articleModels"
import { Dialog, Listbox, RadioGroup, Transition } from "@headlessui/react";
import { ChevronUpDownIcon, CheckIcon, PlusIcon, StarIcon, XMarkIcon } from "@heroicons/react/24/outline";
import journalists from "../journalists/journalists";
import { ArticleService } from "../../../services/articleSerivce";
import ArticleCard from "./article-card";
import Loading from "../shared/loading";
import CreateRanking from "./createRanking";

const categories = [
    "Headlines",
    "Business",
    "Tech",
    "Finance",
    "Politics",
    "Environment"
]

const rankings = () => {

    const articleService = new ArticleService();
    const [articles, setArticles] = useState<IArticleModel[]>([]);
    const [selectedCategory, setSelectedCategory] = useState(categories[0]);
    const [openModal, setOpenModal] = useState(false);
    const [closeModal, setCloseModal] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);

    const UpdateSelected = (selectedMarquee: any) => {
        setSelectedCategory(selectedMarquee);
        getArticles();
    }

    const getArticles = async () => {
        setLoading(true);
        const queryString = `category=${selectedCategory}&items=150&page=1`;
        await articleService.getArticleList(queryString).then((response) => {
            setLoading(false);
            setArticles(response.results.pagedItems);
        }).catch(err => {
            setLoading(false);
        })
    }

    const initialList = useCallback(async () => {
        await getArticles();
    }, [])

    useEffect(() => {
        initialList();
    }, [])

    const createRanking = (openModal: boolean) => {
        return <><Transition.Root show={openModal} as={Fragment}>
            <Dialog as="div" className="relative  z-10" onClose={setOpenModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block" />
                </Transition.Child>
    
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span className="hidden md:inline-block md:h-screen md:align-middle" aria-hidden="true">
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                            enterTo="opacity-100 translate-y-0 md:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 md:scale-100"
                            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                        >
                            <Dialog.Panel className="flex w-full transform text-left text-base transition md:my-8 md:max-w-2xl md:px-4 lg:max-w-4xl">
                                <div className="relative flex w-full items-center  rounded-md overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
                                    <button
                                        type="button"
                                        className="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8"
                                        onClick={() => setOpenModal(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                    <CreateRanking category={selectedCategory}/>

                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root></>
    }

    const categorySelect = () => {
        return <>
            <div className="sm:col-span-3">
                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                    Categories
                </label>
                <div className="">
                    <Listbox value={selectedCategory} onChange={(e) => UpdateSelected(e)}>
                        {({ open }) => (
                            <>
                                <div className="relative mt-1">
                                    <Listbox.Button className="relative w-60 cursor-default rounded-md py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-700 focus:outline-none focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6">
                                        <span className="block truncate">{selectedCategory}</span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute z-10 mt-1 bg-white max-h-60 w-56 overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {categories.map((category) => (
                                                <Listbox.Option
                                                    key={category}
                                                    className={({ active }) =>
                                                        classNames(
                                                            active ? 'bg-green-600' : 'text-gray-800',
                                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                                        )
                                                    }
                                                    value={category}
                                                >
                                                    {({ selected, active }) => (
                                                        <>
                                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                {category}
                                                            </span>

                                                            {selected ? (
                                                                <span
                                                                    className={classNames(
                                                                        active ? 'text-white' : 'text-green-600',
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                    )}
                                                                >
                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div>
            </div></>
    }

    return <>
        <div className="">
            <header>
                <div className="mx-auto max-w-7xl ">
                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Article Rankings</h1>
                    {categorySelect()}
                </div>
            </header>
            <main>
                {!loading ? <>
                    <div className="mx-auto max-w-7xl">
                        <div className="mt-4 border-t border-gray-200 p-2">
                            <button
                                type="button"
                                className="flex items-center rounded-md bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={() => setOpenModal(true)}
                            >
                                <p>New {selectedCategory} ranking</p>
                                <PlusIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                            {articles.map((article) => (
                                <ArticleCard article={article} />
                            ))}
                        </div>
                    </div>
                </> : <>
                    <Loading />
                </>}

            </main>
            {openModal ? <>{createRanking(openModal)}</>: <></>}
        </div>
    </>
}

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default rankings