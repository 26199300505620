/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-hooks/rules-of-hooks */
import { Fragment, useCallback, useEffect, useState } from "react"
import { IJournalistModel } from "../../../models/content-models/journalistModels";
import { JournalistService } from "../../../services/journalistService";
import { UserCircleIcon, PhotoIcon, CheckIcon, ChevronUpDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Listbox, Transition, Switch, Combobox } from "@headlessui/react";
import TinyMceEditor from "./tiny-mce";
import ImageUpload from "./upload-image";
import { BeatLoader } from "react-spinners";
import { ArticleService } from "../../../services/articleSerivce";
import { IArticleModel, IMetaData } from "../../../models/content-models/articleModels";
import { HashMap } from "../../../models/request-models/hashmap";
import { useNavigate } from "react-router-dom";
import { NewsCategories } from "../../../models/constants/categoryConstants";
import AlertError from "../shared/alert-error";
import useSession from "../../../hooks/useSession";

const publishers = [
    {
        name: "MKE Post",
        value: "MKE-Post-Newspaper"
    }
]

const metaDataTypes = [
    { id: 1, name: 'tag' },
    { id: 2, name: 'journalist' },
    { id: 3, name: 'keyword' },
]

const submitArticle = () => {
    const journalistService = new JournalistService();
    const articleService = new ArticleService();

    // images
    const [imageSearchList, setImageSearchList] = useState<IArticleModel[]>([])
    const [selectedImage, setSelectedImage] = useState('')
    const [imageQuery, setImageQuery] = useState('');

    // tokens
    const { userToken } = useSession();
    const navigate = useNavigate();

    // article inputs
    const [title, setTitle] = useState('');
    const [articleLead, setArticleLead] = useState('');
    const [story, setStory] = useState<any>({});
    const [imageCaption, setImageCaption] = useState('');
    const [metaData, setMetaData] = useState<IMetaData[]>([]);
    const [message, setMessage] = useState('');
    const [sponsoredContent, setSponsoredContent] = useState(false);
    const [premiumContent, setPremiumContent] = useState(false);
    const [useExistingPhoto, setUseExistingPhoto] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState(NewsCategories.NEWS_CATEGORIES[0]);
    const [selectedPublisher, setSelectedPublisher] = useState(publishers[0]);
    const [selectedSubCategory, setSelectedSubCategory] = useState<any>(NewsCategories.NEWS_CATEGORIES[0].subcategories[0]);
    const [selectedJournalist, setSelectedJournalist] = useState({} as IJournalistModel);
    const [journalists, setJournalists] = useState([] as IJournalistModel[]);

    const [selected, setSelected] = useState(metaDataTypes[0])
    const [inputValue, setInputValue] = useState('')

    const [loading, setLoading] = useState(true);

    const getWriters = useCallback(async () => {
        const queryString = `items=50&page=1`;
        await journalistService.getAllJournalists(queryString)
            .then((response) => {
                setLoading(false);
                if (response.isSuccessful) {
                    setJournalists(response.results.pagedItems);
                    setSelectedJournalist(response.results.pagedItems[0]);
                }
                else {
                    setMessage(response.errorMessage);
                }
            }).catch(() => {
                setMessage('failed to get journalists');
            })
    }, []);

    const filteredImage: any =
        imageQuery === ''
            ? imageSearchList
            : imageSearchList.filter((image) => {
                return image.imageTag.toLowerCase().includes(filteredImage.toLowerCase())
            })

    const UpdateSelected = (selectedMarquee: any) => {
        setSelectedJournalist(selectedMarquee);
    }

    const UpdateSelectedPublisher = (selected: any) => {
        setSelectedPublisher(selected);
    }

    const updateCategory = (categorySelect: any) => {
        setSelectedCategory(categorySelect);
        setSelectedSubCategory(categorySelect.subcategories[0])
    }

    const updateSubCategory = (subCategorySelect: any) => {
        setSelectedSubCategory(subCategorySelect);
    }

    const addKeyValuePair = () => {
        if (inputValue.trim() === '') return
        setMetaData([...metaData, { type: selected.name, value: inputValue }])
        setInputValue('') // Clear input after adding
    }

    const removeKeyValuePair = (index: number) => {
        setMetaData(metaData.filter((_, i) => i !== index))
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);

        try {
            setLoading(true);
            const storyStr = localStorage.getItem('saved-new-article');
            const plainText = localStorage.getItem('plaintext')
            const photoKeyStr = localStorage.getItem('presigned-key');
            setStory(storyStr);
            let article = {} as IArticleModel;
            article.articleTitle = title
            article.articleLead = articleLead

            if (useExistingPhoto) {
                article.imageTag = `article-images/${imageQuery}`
            } else {
                article.imageTag = photoKeyStr!
            }

            article.articleID = ""
            article.imageCaption = imageCaption
            article.imageSrc = 'AWS'
            article.journalist = selectedJournalist
            article.content = storyStr
            article.plainText = plainText!
            article.metaData = metaData!
            article.sponsoredContent = sponsoredContent
            article.premiumContent = premiumContent
            article.categories = [selectedCategory.category];
            article.publisher = selectedPublisher.name
            article.subCategory = selectedSubCategory
            article.image = ""
            article.journalist.cognitoID = selectedJournalist.cognitoID || ""
            article.journalist.cognitoUsername = selectedJournalist.cognitoUsername || ""
            article.journalist.email = selectedJournalist.email || ""
            article.journalist.phoneNumber = ""
            article.journalist.sources = []

            await articleService.submitNewArticle(article).then((response) => {
                setMessage('successfully submitted article');
                navigate('/');
            }).catch(err => {
                setMessage('failed to submit article');
            }).finally(() => {
                // do something
                setLoading(false);
            })
        }
        catch (err: any) {
        }
    }

    const handleSponsoredCheckboxChange = (event: any) => {
        setSponsoredContent(event.target.checked);
    };

    const handlePremiumCheckboxChange = (event: any) => {
        setPremiumContent(event.target.checked);
    };

    useEffect(() => {
        getWriters();
    }, [getWriters]);

    const metaDataSelect = () => {

        return <>
            <div className="m-4">
                <h3 className="text-sm font-medium leading-6 text-gray-900">Meta Data Pairs</h3>
                <ul className="mt-2">
                    {metaData.map((pair, index) => (
                        <li key={index} className="flex justify-between items-center mb-2 p-2 bg-gray-100 rounded-md">
                            <span>{pair.type}: {pair.value}</span>
                            <button
                                type="button"
                                onClick={() => removeKeyValuePair(index)}
                                className="inline-flex justify-center rounded-md bg-red-600 px-2 py-1 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                            >
                                <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="m-4 flex">
                <div className="w-36">
                    <Listbox value={selected} onChange={setSelected}>
                        {({ open }) => (
                            <>
                                <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">Key</Listbox.Label>
                                <div className="relative mt-2">
                                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <span className="block truncate">{selected.name}</span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </Listbox.Button>

                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {metaDataTypes.map((type) => (
                                                <Listbox.Option
                                                    key={type.id}
                                                    className={({ active }) =>
                                                        `${active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                                        } relative cursor-default select-none py-2 pl-3 pr-9`
                                                    }
                                                    value={type}
                                                >
                                                    {({ selected, active }) => (
                                                        <>
                                                            <span className={`${selected ? 'font-semibold' : 'font-normal'} block truncate`}>
                                                                {type.name}
                                                            </span>
                                                            {selected ? (
                                                                <span
                                                                    className={`${active ? 'text-white' : 'text-indigo-600'
                                                                        } absolute inset-y-0 right-0 flex items-center pr-4`}
                                                                >
                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div>
                <div className="ml-2 w-36">
                    <label htmlFor="metadata" className="block text-sm font-medium leading-6 text-gray-900">
                        Value
                    </label>
                    <div className="mt-2">
                        <input
                            type="text"
                            name="metadata"
                            id="metadata"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            className="block w-full p-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className="ml-4 flex items-end">
                    <button
                        type="button"
                        onClick={addKeyValuePair}
                        className="inline-flex justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                        Add
                    </button>
                </div>
            </div>
        </>
    }

    const publisherSelect = () => {
        return <>
            <div className="sm:col-span-3">
                <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                    Publisher
                </label>
                <div className="">
                    <Listbox value={selectedPublisher} onChange={(e) => UpdateSelectedPublisher(e)}>
                        {({ open }) => (
                            <>
                                <div className="relative mt-1">
                                    <Listbox.Button className="relative w-60 cursor-default rounded-md py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-700 focus:outline-none focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6">
                                        <span className="block truncate">{selectedPublisher.name}</span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        show={open}
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute z-10 mt-1 bg-white max-h-60 w-56 overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {publishers.map((publisher) => (
                                                <Listbox.Option
                                                    key={publisher.value}
                                                    className={({ active }) =>
                                                        classNames(
                                                            active ? 'bg-green-600' : 'text-gray-800',
                                                            'relative cursor-default select-none py-2 pl-3 pr-9'
                                                        )
                                                    }
                                                    value={publisher}
                                                >
                                                    {({ selected, active }) => (
                                                        <>
                                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                {publisher.name}
                                                            </span>

                                                            {selected ? (
                                                                <span
                                                                    className={classNames(
                                                                        active ? 'text-white' : 'text-green-600',
                                                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                    )}
                                                                >
                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </>
                        )}
                    </Listbox>
                </div>
            </div></>
    }

    return <>
        <div className="py-10">
            <header>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Submit Article</h1>
                    {message !== '' ? <>
                        <AlertError message={message} />
                    </> : <>
                    </>
                    }
                </div>
            </header>
            <main>
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <form>
                        <div className="space-y-12">
                            <div className="border-b border-gray-900/10 pb-12">

                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-4">
                                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                            Title
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    autoComplete="title"
                                                    onChange={(e) => setTitle(e.target.value)}
                                                    value={title}
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder=""
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-span-full">
                                        <label htmlFor="about" className="block text-sm pl-1 font-medium leading-6 text-gray-900">
                                            Article Leade
                                        </label>
                                        <div className="mt-2">
                                            <textarea
                                                id="about"
                                                name="about"
                                                rows={3}
                                                onChange={(e) => setArticleLead(e.target.value)}
                                                value={articleLead}
                                                className="block w-full rounded-md border-0 pl-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                defaultValue={''}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between">
                                        <span className="flex flex-grow flex-col">
                                            <label className="text-sm font-medium leading-6 text-gray-900">
                                                Use Existing Photo
                                            </label>
                                        </span>
                                        <Switch
                                            checked={useExistingPhoto}
                                            onChange={setUseExistingPhoto}
                                            className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
                                        >
                                            <span
                                                aria-hidden="true"
                                                className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                                            />
                                        </Switch>
                                    </div>
                                    {useExistingPhoto ? <>
                                        <div className="sm:col-span-4">
                                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                                Image Tag
                                            </label>
                                            <div className="mt-2">
                                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                    <input
                                                        type="text"
                                                        name="imageQuery"
                                                        id="imageQuery"
                                                        autoComplete="imageQuery"
                                                        onChange={(e) => setImageQuery(e.target.value)}
                                                        value={imageQuery}
                                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                        placeholder=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </> : <>
                                        <ImageUpload bucketName={'article-images'} />
                                        <div className="sm:col-span-4">
                                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                                Image Caption
                                            </label>
                                            <div className="mt-2">
                                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                    <input
                                                        type="text"
                                                        name="title"
                                                        id="title"
                                                        autoComplete="title"
                                                        onChange={(e) => setImageCaption(e.target.value)}
                                                        value={imageCaption}
                                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                        placeholder=""
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </>}


                                </div>
                            </div>

                            <div className="border-b border-gray-900/10 pb-12">
                                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">



                                    <div className="sm:col-span-3">
                                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                            Journalist
                                        </label>
                                        <div className="">
                                            <Listbox value={selectedJournalist} onChange={(e) => UpdateSelected(e)}>
                                                {({ open }) => (
                                                    <>
                                                        <div className="relative mt-1 mb-1">
                                                            <Listbox.Button className="relative w-60 cursor-default rounded-md py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-700 focus:outline-none focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6">
                                                                <span className="block truncate">{selectedJournalist.firstName} {selectedJournalist.lastName}</span>
                                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                </span>
                                                            </Listbox.Button>
                                                            <Transition
                                                                show={open}
                                                                as={Fragment}
                                                                leave="transition ease-in duration-100"
                                                                leaveFrom="opacity-100"
                                                                leaveTo="opacity-0"
                                                            >
                                                                <Listbox.Options className="absolute z-10 mt-1 bg-white max-h-60 w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                    {journalists.map((journalist) => (
                                                                        <Listbox.Option
                                                                            key={journalist.journalistID}
                                                                            className={({ active }) =>
                                                                                classNames(
                                                                                    active ? 'bg-green-600' : 'text-gray-800',
                                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                )
                                                                            }
                                                                            value={journalist}
                                                                        >
                                                                            {({ selected, active }) => (
                                                                                <>
                                                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                                        {journalist.firstName} {journalist.lastName}
                                                                                    </span>

                                                                                    {selected ? (
                                                                                        <span
                                                                                            className={classNames(
                                                                                                active ? 'text-white' : 'text-green-600',
                                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                            )}
                                                                                        >
                                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                        </span>
                                                                                    ) : null}
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    ))}
                                                                </Listbox.Options>
                                                            </Transition>
                                                        </div>
                                                    </>
                                                )}
                                            </Listbox>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                            News Category
                                        </label>
                                        <div className="">
                                            <Listbox value={selectedCategory} onChange={(e) => updateCategory(e)}>
                                                {({ open }) => (
                                                    <>
                                                        <div className="relative mt-1 mb-1">
                                                            <Listbox.Button className="relative w-60 cursor-default rounded-md py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-700 focus:outline-none focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6">
                                                                <span className="block truncate">{selectedCategory.category}</span>
                                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                </span>
                                                            </Listbox.Button>
                                                            <Transition
                                                                show={open}
                                                                as={Fragment}
                                                                leave="transition ease-in duration-100"
                                                                leaveFrom="opacity-100"
                                                                leaveTo="opacity-0"
                                                            >
                                                                <Listbox.Options className="absolute z-10 mt-1 bg-white max-h-60 w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                    {NewsCategories.NEWS_CATEGORIES.map((category) => (
                                                                        <Listbox.Option
                                                                            key={category.category}
                                                                            className={({ active }) =>
                                                                                classNames(
                                                                                    active ? 'bg-green-600' : 'text-gray-800',
                                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                )
                                                                            }
                                                                            value={category}
                                                                        >
                                                                            {({ selected, active }) => (
                                                                                <>
                                                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                                        {category.category}
                                                                                    </span>

                                                                                    {selected ? (
                                                                                        <span
                                                                                            className={classNames(
                                                                                                active ? 'text-white' : 'text-green-600',
                                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                            )}
                                                                                        >
                                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                        </span>
                                                                                    ) : null}
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    ))}
                                                                </Listbox.Options>
                                                            </Transition>
                                                        </div>
                                                    </>
                                                )}
                                            </Listbox>
                                        </div>
                                    </div>
                                    <div className="sm:col-span-3">
                                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                                            Sub Category
                                        </label>
                                        <div className="">
                                            <Listbox value={selectedSubCategory} onChange={(e) => updateSubCategory(e)}>
                                                {({ open }) => (
                                                    <>
                                                        <div className="relative mt-1 mb-1">
                                                            <Listbox.Button className="relative w-60 cursor-default rounded-md py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-700 focus:outline-none focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6">
                                                                <span className="block truncate">{selectedSubCategory}</span>
                                                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                </span>
                                                            </Listbox.Button>
                                                            <Transition
                                                                show={open}
                                                                as={Fragment}
                                                                leave="transition ease-in duration-100"
                                                                leaveFrom="opacity-100"
                                                                leaveTo="opacity-0"
                                                            >
                                                                <Listbox.Options className="absolute z-10 mt-1 bg-white max-h-60 w-full overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                    {selectedCategory.subcategories.map((category: any) => (
                                                                        <Listbox.Option
                                                                            key={category}
                                                                            className={({ active }) =>
                                                                                classNames(
                                                                                    active ? 'bg-green-600' : 'text-gray-800',
                                                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                                                )
                                                                            }
                                                                            value={category}
                                                                        >
                                                                            {({ selected, active }) => (
                                                                                <>
                                                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                                        {category}
                                                                                    </span>

                                                                                    {selected ? (
                                                                                        <span
                                                                                            className={classNames(
                                                                                                active ? 'text-white' : 'text-green-600',
                                                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                            )}
                                                                                        >
                                                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                        </span>
                                                                                    ) : null}
                                                                                </>
                                                                            )}
                                                                        </Listbox.Option>
                                                                    ))}
                                                                </Listbox.Options>
                                                            </Transition>
                                                        </div>
                                                    </>
                                                )}
                                            </Listbox>
                                        </div>
                                    </div>
                                    {publisherSelect()}
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <TinyMceEditor initialValue={{ initialValue: "Welcome to TinyMCE!" }} />
                        </div>
                        <fieldset>
                            <legend className="sr-only">Notifications</legend>
                            <div className="space-y-5">
                                <div className="relative flex items-start">
                                    <div className="flex h-6 items-center">
                                        <input
                                            id="comments"
                                            aria-describedby="comments-description"
                                            name="comments"
                                            type="checkbox"
                                            checked={sponsoredContent}
                                            onChange={e => handleSponsoredCheckboxChange(e)}
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                    </div>
                                    <div className="ml-3 text-sm leading-6">
                                        <label htmlFor="comments" className="font-medium text-gray-900">
                                            Sponsored Article
                                        </label>
                                        <p id="comments-description" className="text-gray-500">
                                            Check if the article is being sponsored by another entity
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset>
                            <legend className="sr-only">Notifications</legend>
                            <div className="space-y-5">
                                <div className="relative flex items-start">
                                    <div className="flex h-6 items-center">
                                        <input
                                            id="comments"
                                            aria-describedby="comments-description"
                                            name="comments"
                                            type="checkbox"
                                            checked={premiumContent}
                                            onChange={e => handlePremiumCheckboxChange(e)}
                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                    </div>
                                    <div className="ml-3 text-sm leading-6">
                                        <label htmlFor="comments" className="font-medium text-gray-900">
                                            Premium Article
                                        </label>
                                        <p id="comments-description" className="text-gray-500">
                                            Check if the article is a premium article
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                        {metaDataSelect()}
                        <div className="mt-6 flex items-center justify-end gap-x-6">
                            <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className={`
                                "rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"`
                                }
                                onClick={e => handleSubmit(e)}
                                disabled={loading && (userToken.role !== 'admin' || userToken.role !== 'journalist')}
                            >
                                {loading ? (
                                    <>
                                        <BeatLoader
                                            color="#FFFFFF"
                                            loading={loading}
                                            size={18}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        />
                                    </>
                                ) : (
                                    <>Submit For Review</>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    </>
}

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ')
}

export default submitArticle