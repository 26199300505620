/* eslint-disable react-hooks/rules-of-hooks */
import { Disclosure, Menu, Transition } from "@headlessui/react"
import React, { Fragment, useCallback, useEffect, useState } from "react"
import { CognitoService } from "../../services/cognitoService"
import UserNav from "./user-nav"
import UnauthorizedNav from "./unauthorized-nav"
import useSession from "../../hooks/useSession"

const nav = () => {
    const cognitoService = new CognitoService();
    const [authorized, setAuthorized] = useState(false);

    const authorize = useCallback(async () => {
        await cognitoService.promiseAuthenticated().then((response: boolean) => {
            setAuthorized(response);
        })
    }, []);

    useEffect(() => {
        authorize();
    },[])

    return <>
        <Disclosure as="nav" className="bg-white shadow">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 justify-between">
                            {authorized ? <>
                                <UserNav />
                            </> : <>
                                <UnauthorizedNav />
                            </>}
                        </div>
                    </div>
                </>
            )}
        </Disclosure>
    </>
}

export default nav;