/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useState } from "react";
import { IArticleModel } from "../../../models/content-models/articleModels";
import { ArticleService } from "../../../services/articleSerivce";
import { IBaseResult } from "../../../models/baseResults";
import LoadingIndicator from "../shared/loading";
import AlertMessage from "../alerts/alert-error";
import parse from "html-react-parser";
import { NewsletterService } from "../../../services/newsletterService";
import { INewsletterModel } from "../../../models/content-models/newsletterModel";

const resendNewsletters = () => {

    const articleService = new ArticleService()
    const newsletterService = new NewsletterService();
    const [articles, setArticles] = useState<IArticleModel[]>()
    const [newsletter, setNewsletter] = useState<INewsletterModel>()

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState('')

    const emailArticles = async () => {
        const query = "sort=desc&items=3&page=1";
        await articleService.getArticleCollection(query).then((response: IBaseResult<any>) => {
            console.log('newsletter articles', articles)
            if (response.isSuccessful) {
                setArticles(response.results.pagedItems)

            } else {
                setError(!response.isSuccessful)
                setMessage(response.errorMessage)
            }
        }).catch(err => {
            setError(true)
            console.log(err)
            setMessage('application error')
        }).finally(() => {
            setLoading(false)
        })
    }

    const getNewsletter = async () =>{
        await newsletterService.getNewsletter().then((response: IBaseResult<INewsletterModel>) =>{
            if(response.isSuccessful){
                setNewsletter(response.result)
            }
            else{
                setError(!response.isSuccessful)
                setMessage(response.errorMessage)
            }
        }).catch(err => {
            setError(true)
            console.log(err)
            setMessage('application error')
        }).finally(() => {
            setLoading(false)
        })
    }

    const generateNewsletter = async (e: any) =>{
        e.preventDefault()
        setLoading(true)

        await newsletterService.generateTodaysNewsletter().then(async (response: IBaseResult<boolean>) =>{
            if(response.isSuccessful){
                setLoading(false)
                await getNewsletter()
            }
            else{
                setError(!response.isSuccessful)
                setMessage(response.errorMessage)
            }
        }).catch(err => {
            setError(true)
            console.log(err)
            setMessage('application error')
        }).finally(() => {
            setLoading(false)
        })
    }

    const sendEmail = async (e: any) => {
        e.preventDefault()

    }

    const initialArticlelist = useCallback(async () => {
        await getNewsletter();
    }, [])

    useEffect(() => {
        initialArticlelist();
    }, [initialArticlelist])

    return <>
        {/* view subscribers tab */}
        {/* view template tab */}
        {/* scheduled newsletter */}
        {/* previous newsletters */}
        {!loading ? <>
            {!error ? <>
                {newsletter === null ? <>
                    <button
                    type="button"
                    className="flex items-end rounded-md bg-indigo-600 p-2 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={(e) => generateNewsletter(e)}
                >
                    <p>Generate Newsletter</p>
                </button>
                </> : <>
                <p className="prose child dark:text-slate-200">{parse(`${newsletter?.html}`)}</p>
                </>}
            </> : <>
                <AlertMessage message={message} severity={2} />
            </>}
        </> : <>
            <LoadingIndicator />
        </>}

    </>
}

export default resendNewsletters;