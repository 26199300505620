import { format } from "date-fns";
import { IArticleModel } from "../../../models/content-models/articleModels";

const articleCard = ({article}: any) => {

    return <>
        <article key={article.articleID} className="flex max-w-xl flex-col items-start justify-between border-t">
            <div className="flex items-center gap-x-4 text-xs">
                <time className="text-gray-500">
                    {`${format(
                        article.submissionDate,
                        "EEEE, MMMM dd, yyyy"
                    )}`}
                </time>
                <a
                    className="relative rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                >
                    {article.rank}
                </a>
            </div>
            <div className="group relative">
                <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a >
                        <span className="absolute inset-0" />
                        {article.articleTitle}
                    </a>
                </h3>
                <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{article.articleLead}</p>
            </div>
            <div className="relative mt-8 flex items-center gap-x-4">
                <img src={article.journalist.profileImageUrl} alt="" className="h-10 w-10 rounded-full bg-gray-50" />
                <div className="text-sm leading-6">
                    <p className="font-semibold text-gray-900">
                        <a href={article.image}>
                            <span className="absolute inset-0" />
                            {article.journalist.firstName} {article.journalist.lastName}
                        </a>
                    </p>
                    <p className="text-gray-600">{article.journalist.expertiseCategories}</p>
                </div>
            </div>
        </article>
    </>
}

export default articleCard;