import { useState } from "react";
import { NewsCategories } from "../../../models/constants/categoryConstants";
import ImageUpload from "../submit-article/upload-image";
import { BeatLoader } from "react-spinners";
import { JournalistService } from "../../../services/journalistService";
import { IJournalistModel } from "../../../models/content-models/journalistModels";
import { useNavigate } from "react-router-dom";

const SubmitJournalist = () => {

    const journalistService =  new JournalistService();
    const [firstName, setFirstName] = useState('');
    const [middle, setMiddle] = useState('');
    const [lastName, setLastName] = useState('');
    const [byline, setByline] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(NewsCategories.NEWS_CATEGORIES[0]);

    const updateCategory = (categorySelect: any) => {
        setSelectedCategory(categorySelect);
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        const photoKeyStr = localStorage.getItem('presigned-key');
        
        let expertise = [] as string[]
        expertise.push(selectedCategory.category)

        const journalist = {
            journalistID: '',
            firstName: firstName,
            middle: middle,
            lastName: lastName,
            byline: byline,
            expertiseCategories: expertise,
            imageTag: photoKeyStr,
            imageSrc: 'AWS'
        } as IJournalistModel

        await journalistService.postJournalist(journalist).then(response =>{
            setMessage('successfully submitted journalist');
            navigate('/');
        }).catch(err =>{
            setMessage('failed to submit journalist');
        }).finally(() =>{
            setLoading(false);
        })
    }

    return <>
        <main className="m-4">
            <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                <form>
                    <div className="space-y-12">
                        <div className="border-b border-gray-900/10 pb-12">
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 ">
                                <div className="grid grid-cols-3">
                                    {/* first name */}
                                    <div className="col-span-1 mr-4">
                                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                            First Name
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    autoComplete="title"
                                                    onChange={(e) => setFirstName(e.target.value)}
                                                    value={firstName}
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder=""
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* middle name */}
                                    <div className="col-span-1 mr-4">
                                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                            Middle
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    autoComplete="title"
                                                    onChange={(e) => setMiddle(e.target.value)}
                                                    value={middle}
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder=""
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* last name */}
                                    <div className="col-span-1">
                                        <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                                            Last Name
                                        </label>
                                        <div className="mt-2">
                                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                                <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    autoComplete="title"
                                                    onChange={(e) => setLastName(e.target.value)}
                                                    value={lastName}
                                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    placeholder=""
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-full">
                                    <label htmlFor="about" className="block text-sm pl-1 font-medium leading-6 text-gray-900">
                                        Byline
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                            id="about"
                                            name="about"
                                            rows={3}
                                            onChange={(e) => setByline(e.target.value)}
                                            value={byline}
                                            className="block w-full rounded-md border-0 pl-1 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                            defaultValue={''}
                                        />
                                    </div>
                                </div>

                                <ImageUpload bucketName={'journalist-images'} />
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                            <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                onClick={e => handleSubmit(e)}
                                disabled={loading}
                            >
                                {loading ? (
                                    <>
                                        <BeatLoader
                                            color="#FFFFFF"
                                            loading={loading}
                                            size={18}
                                            aria-label="Loading Spinner"
                                            data-testid="loader"
                                        />
                                    </>
                                ) : (
                                    <>Submit Journalist</>
                                )}
                            </button>
                        </div>
                </form>
            </div>
        </main>
    </>
}

export default SubmitJournalist;