/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useCallback, useEffect } from "react";
import { IJournalistModel } from "../../../models/content-models/journalistModels";
import { JournalistService } from "../../../services/journalistService";
import Loading from "../shared/loading";
import JournalistCard from "./journalist-card";
import { useNavigate } from "react-router-dom";

const journalists = () => {

    const writerService = new JournalistService();
    const [journalists, setJournalists] = useState([] as IJournalistModel[]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [selectedJournalist, setSelectedJournalist] = useState(journalists[0]);

    const newJournalist = (e: any) => {
        e.preventDefault;
        navigate('/journalists/submit')
    }

    const getWriters = useCallback(async () => {
        const queryString = `items=50&page=1`;
        await writerService.getAllJournalists(queryString).then((response) => {
            setJournalists(response.results.pagedItems);
            setLoading(false);
        })
    }, [])

    useEffect(() => {
        getWriters();
    }, [getWriters]);

    return <>
        <div className="py-10">
            <header>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Journalists</h1>
                    <button
                        type="submit"
                        className="rounded-md bg-indigo-600 px-3 py-2 mb-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={e => newJournalist(e)}
                        disabled={loading}
                    >
                       + Submit Journalist
                    </button>
                </div>
            </header>
            <main>
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">{!loading ? <>
                    <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                        {journalists.map((journalist) => (
                            <JournalistCard journalist={journalist} />
                        ))}
                    </ul>
                </> : <>
                    <Loading />
                </>}
                </div>
            </main>
        </div>
    </>
}

export default journalists