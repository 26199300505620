/* eslint-disable react/jsx-no-undef */
import './App.css';
import Nav from './app/nav/nav';
import RouteHandler from './routeHandler';

const App = () => {
  return <>
    <Nav />
    <RouteHandler />
  </>
}

export default App;
